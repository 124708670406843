
export default class Templates {

  constructor() {
    this.templates = [
      [
        [null],
        [null]
      ],

      [
        [null, null],
        [null, null]
      ],

      [
        [null, null]
      ],

      [
        [null, null],
        [null, null],
        [null, null]
      ],

      [
        [null],
        [null, null]
      ],

      [
        [null, null],
        [null]
      ],

      [
        [null,null,null],
        [null]
      ],

      [
        [null],
        [null,null,null]
      ],

      [
        [null,null,null],
        [null, null],
        [null, null]
      ],

      [
        [null, null],
        [null],
        [null,null,null]
      ],

      [
        [null, null, null],
        [null],
        [null,null]
      ],

      [
        [null],
        [null,null],
        [null,null,null]
      ]
    ];

    this.templateImages = [
      'https://firebasestorage.googleapis.com/v0/b/selfwee-85212.appspot.com/o/templates%2Ftemplate1.png?alt=media&token=001d8379-eb67-4d26-8f5a-a82b6e94a898',
      'https://firebasestorage.googleapis.com/v0/b/selfwee-85212.appspot.com/o/templates%2Ftemplate2.png?alt=media&token=dba02925-a72e-45c5-8140-12681f5b6b7f',
      'https://firebasestorage.googleapis.com/v0/b/selfwee-85212.appspot.com/o/templates%2Ftemplate3.png?alt=media&token=115f46b7-6c04-484d-9227-cae7b812a257',
      'https://firebasestorage.googleapis.com/v0/b/selfwee-85212.appspot.com/o/templates%2Ftemplate4.png?alt=media&token=f290f50b-54bb-4eb8-a15a-ebad35eb766f',
      'https://firebasestorage.googleapis.com/v0/b/selfwee-85212.appspot.com/o/templates%2Ftemplate5.png?alt=media&token=37ba4bbd-3ec1-47ee-a34a-0c26b14daec8',
      'https://firebasestorage.googleapis.com/v0/b/selfwee-85212.appspot.com/o/templates%2Ftemplate6.png?alt=media&token=29483993-4538-4225-a715-248074a176f2',
      'https://firebasestorage.googleapis.com/v0/b/selfwee-85212.appspot.com/o/templates%2Ftemplate7.png?alt=media&token=57682699-eefb-4279-b153-7d1d6bb2e9dc',
      'https://firebasestorage.googleapis.com/v0/b/selfwee-85212.appspot.com/o/templates%2Ftemplate8.png?alt=media&token=ad5571b6-4468-4641-b506-f940360f1e24',
      'https://firebasestorage.googleapis.com/v0/b/selfwee-85212.appspot.com/o/templates%2Ftemplate9.png?alt=media&token=760612b0-a1a1-43ca-b9e3-c17e6f96f831',
      'https://firebasestorage.googleapis.com/v0/b/selfwee-85212.appspot.com/o/templates%2Ftemplate10.png?alt=media&token=247cff81-7892-4fbc-ac4c-0c5a91abab62',
      'https://firebasestorage.googleapis.com/v0/b/selfwee-85212.appspot.com/o/templates%2Ftemplate11.png?alt=media&token=f72366c7-ca02-4679-853f-1d8026f70a8d',
      'https://firebasestorage.googleapis.com/v0/b/selfwee-85212.appspot.com/o/templates%2Ftemplate12.png?alt=media&token=c7580503-9239-4023-9672-a7c3a9242ac7'
    ]
  }

  getTemplate(index) {
    return this.templates[index];
  }

  getTemplateImage(index) {
    return this.templateImages[index];
  }

}
