import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/storage';
import 'firebase/functions';

const firebaseConfig = {
  apiKey: "AIzaSyC7o1S8X_95cMVVwzAhyuKQN_Kll4txNXw",
  authDomain: "selfwee-85212.firebaseapp.com",
  databaseURL: "https://selfwee-85212.firebaseio.com",
  projectId: "selfwee-85212",
  storageBucket: "selfwee-85212.appspot.com",
  messagingSenderId: "403007782480",
  appId: "1:403007782480:web:7bdefc23e8720cd9339b55"
};

class Firebase {
  constructor() {

    if (!app.apps.length) {
      app.initializeApp(firebaseConfig);
    }

    this.auth = app.auth();
    this.db = app.database();
  }
}

export default Firebase;
