import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';

import Invitation from './invitation';

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/invitation/:id">
          <Invitation />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
