import React, { useState, useEffect } from 'react';
import Firebase from './firebase';
import {
  useParams
} from 'react-router-dom';

import Templates from './templates';

const firebase = new Firebase();

function Invitation(props) {

  const [userLogged, setUserLogged] = useState(false);
  const [invite, setInvite] = useState();
  const [collage, setCollage] = useState();
  const [w, setWindowWidth] = useState(window.innerWidth);

  let { id } = useParams();

  firebase.auth.signInAnonymously();

  useEffect(() => {
    firebase.auth.onAuthStateChanged(user => {
      if (user) {
        setUserLogged(true);
      }
    })
  });

  useEffect(() => {
    if (userLogged) {
      const unsubscribe = firebase.db.ref('/invitations/' + id)
        .once('value', snapshot => {
          setInvite(snapshot.val());
        });

      return () => unsubscribe();
    }
  },[userLogged, id]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    }

    window.addEventListener('resize', handleResize)
  });

  useEffect(() => {
    if (invite) {
      const unsubscribe = firebase.db.ref('/collages/' + invite.sender_id + '/' + invite.collage_id)
        .once('value', snapshot => {
          setCollage(snapshot.val());
        });

      return () => unsubscribe();
    }
  },[invite]);

  const getImageURL = (image) => {
    if (image) {
      return image.url;
    } else {
      return 'https://firebasestorage.googleapis.com/v0/b/selfwee-85212.appspot.com/o/templates%2Fwaiting-placeholder.png?alt=media&token=2e38d9ee-11eb-4dc2-8702-f908cdddf1b5';
    }
  }

  const renderCollage = () => {

    if (!collage) {
      return null;
    }

    const templatesCollection = new Templates();
    const template = templatesCollection.getTemplate(collage.template_id);

    Object.keys(collage.images).map(key => {
      const imgObj = collage.images[key];
      return template[imgObj.row][imgObj.col] = imgObj;
    });

    return template.map((row, rowIndex) => {

      const gutter = 3;
      const imgWidth = (w - (row.length + 1) * gutter * 2) / row.length;

      return (
        <div key={rowIndex} style={{ margin: gutter }}>
          {
            row.map((col, colIndex) =>
              <a key={colIndex} href={invite.deepLink}><img key={colIndex} style={{ height: imgWidth, width: imgWidth, marginLeft: gutter, marginRight: gutter }} src={getImageURL(template[rowIndex][colIndex])} /></a>
            )
          }
        </div>
      )
    });
  }

  return (

    <div>

      <h3 style={{ margin: 10 }}>{ (collage) ? collage.title : null }</h3>

      { (collage) ? renderCollage() : null }

    </div>
  )
}

export default Invitation;
